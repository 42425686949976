import { defineStore } from "pinia";
import { ref } from "vue";
import type { Ref } from "vue";

export const useFormStore = defineStore(
  "form",
  () => {
    const formOptions: Ref<Record<string, any>> = ref({});

    const fetchFormOptions = async () => {
      const supabase = useSupabaseClient();
      const EXPIRY_HOURS = 24;
      const form = JSON.parse(localStorage.getItem("form") || "{}");
      const formExpiryTime = localStorage.getItem("formExpiryTime");

      const currentTime = dayjs();
      const expiryTime = formExpiryTime ? dayjs(formExpiryTime) : null;

      if (form && expiryTime && currentTime.diff(expiryTime, "hour") < EXPIRY_HOURS) return;

      try {
        const { data: formOptionsData, error } = await supabase.from("form_options").select("options").single();

        if (error) throw error;

        if (formOptionsData) {
          formOptions.value = formOptionsData.options;
          localStorage.setItem("formExpiryTime", currentTime.toISOString());
        }
      } catch (err) {
        console.error("An unexpected error occurred:", err);
      }
    };

    const flushCache = () => {
      localStorage.removeItem("form");
      localStorage.removeItem("formExpiryTime");
    }

    const insertIndustryPosition = async (industryPosition: { name: string; industry_id: number; added_by: string }) => {
      const ip = await $api<{ id: number; name: string; industry_id: number; user_added: boolean }>(API_ROUTES.form, {
        method: "post",
        body: industryPosition,
      });
      if (!ip) throw createError({ message: ERROR_MESSAGES.error_adding_industry_position });
      formOptions.value.industry_positions.push(ip);
      flushCache();
      return ip;
    };

    return { formOptions, fetchFormOptions, insertIndustryPosition };
  },
  {
    persist: {
      storage: persistedState.localStorage,
    },
  }
);
